<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="demo-spacing-0">
              <b-alert
                      variant="primary"
                      show
              >
                <!--<div class="alert-body" >
                  <span><strong>{{getCodeLabel("company_type",companyInformation.company_type)}}名称:{{companyInformation.company_name}}</strong> </span>
                </div>-->
              </b-alert>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input
                      v-model="searchKey"
                      class="d-inline-block mr-1"
                      placeholder="搜索关键字..."
              />-->
              <b-button
                variant="primary"
                :to="{ name: 'apps-warehouserelation-edit',query:{id: this.id}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-warehouserelation-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <template #cell(store_brand_name)="data">
          {{getCodeLabel('store_brand', data.item.store_brand_id)}}
        </template>

        <template #cell(warehouse_id)="data">
          {{getCodeLabel('warehouse', data.item.warehouse_id)}}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->

<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-warehouserelation-edit', query: { id: data.item.warehouse_id, companyName:data.item.id} }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

           <b-dropdown-item  @click="deleteData(data.item.id)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import warehouserelationUseList from './warehouserelationUseList'
import warehouserelationStore from './warehouserelationStore'
import companyStore from "@/views/apps/company/companyStore"
import { useRouter } from '@core/utils/utils'
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data(){
    return{
      id:0,
      companyName:"",
      companyInformation:{},
      selected: [],
    }
  },

  setup() {
    // Register module
    const toast = useToast()
    if (!store.hasModule('warehouserelation')) store.registerModule('warehouserelation', warehouserelationStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouserelation')) store.unregisterModule('warehouserelation')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const router = useRouter()

    const getCompany = function () {
      store.dispatch('company/view', {id: this.id}).then(res => {
        this.companyInformation = res.data.data
      })
    }

    const deleteData = function(data) {
      if (confirm("确定删除吗?")){
        store.dispatch('warehouserelation/delete', {id: data}).then(res => {
          if (res.data.code==0){
            toast.success("删除成功")
          }else {
            toast.error("删除失败")
          }
          refetchData()
        })
      }
    }
    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      warehouseid,
      // UI
    } = warehouserelationUseList(router.route.value.query.id)

    return {
      searchList,
      deleteData,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      getCompany,
      warehouseid,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    this.companyName = this.$route.query.companyName || ""
    this.getCompany()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
